<template>
    <div>
        <el-form
            :model="formData"
            :rules="formRules"
            ref="form"
            size="mini"
            :disabled="type === 'detail'"
        >
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item :label="'用户账号'" prop="account">
                        <el-input v-model="formData.account"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item :label="'用户密码'" prop="password">
                        <el-input
                            v-model="formData.password"
                            type="password"
                            :placeholder="'留空表示不修改密码'"
                        ></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item :label="'语言'" prop="languageCode">
                        <el-select v-model="formData.languageCode" filterable allow-create>
                            <el-option
                                v-for="item in keyValues.languages"
                                :key="item"
                                :label="item"
                                :value="item"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item :label="'名称'" prop="name">
                        <el-input
                            v-model="userLanguage.name"
                            style="width:100%"
                            @change="changeName"
                        ></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item :label="'用户类型'" prop="type">
                        <el-select filterable v-model="formData.type">
                            <el-option
                                v-for="item in keyValues.userType"
                                :key="item.key"
                                :value="item.key"
                                :label="item.value"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item :label="'排序'" prop="rank">
                        <el-input-number
                            v-model="formData.rank"
                            size="small"
                            style="width:100%"
                            :min="0"
                        ></el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item :label="'启用状态'" prop="state">
                        <el-col>
                            <el-radio
                                v-model="formData.state"
                                label="Y"
                            >{{'启用'}}</el-radio>
                            <el-radio
                                v-model="formData.state"
                                label="N"
                            >{{'禁用'}}</el-radio>
                        </el-col>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item :label="'是否删除'" prop="isDeleted">
                        <el-col>
                            <el-radio
                                v-model="formData.isDeleted"
                                :label="true"
                            >{{'是'}}</el-radio>
                            <el-radio
                                v-model="formData.isDeleted"
                                :label="false"
                            >{{'否'}}</el-radio>
                        </el-col>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item
                        :label="'附加信息'"
                        prop="seatConfigRules"
                    >
                        <ex-edit-table-form
                            ref="editTableForm"
                            :rules="editTableRules"
                            size="mini"
                            :table-props="{
                                        stripe: true,
                                        border: true,
                                        data: editTable,
                                        size: 'mini',
                                        height: '300px'
                                       }"
                            table-style="width: 100%"
                            :disabled="type === 'detail'"
                        >
                            <!-- 使用作用域插槽 -->
                            <template slot-scope="editScope">
                                <el-table-column
                                    align="center"
                                    :label="'键'"
                                >
                                    <template slot-scope="scope">
                                        <el-form-item
                                            v-if="scope.row === editScope.editingRow"
                                            prop="key"
                                        >
                                            <el-select
                                                v-model="scope.row.key"
                                                filterable
                                                allow-create
                                                :placeholder="'请选择'"
                                            >
                                                <el-option
                                                    v-for="item in keyOptions"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value"
                                                ></el-option>
                                            </el-select>
                                        </el-form-item>
                                        <span v-else>{{ scope.row.key}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    align="center"
                                    :label="'值'"
                                >
                                    <template slot-scope="scope">
                                        <el-form-item
                                            v-if="scope.row === editScope.editingRow"
                                            prop="value"
                                        >
                                            <el-input v-model="scope.row.value"></el-input>
                                        </el-form-item>
                                        <span v-else>{{ scope.row.value}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    align="center"
                                    :label="'操作'"
                                    type="action"
                                >
                                    <template slot-scope="scope">
                                        <el-button
                                            type="text"
                                            @click="handleEditTableDelete(scope.$index)"
                                        >{{'删除'}}</el-button>
                                    </template>
                                </el-table-column>
                            </template>
                        </ex-edit-table-form>
                        <div
                            class="ui-align--center ui-font--xxlarg"
                            style="font-size: 24px"
                            v-if="type !== 'detail'"
                        >
                            <i class="el-icon-circle-plus" @click="handleEditTableRowAdd"></i>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>
<script>
export default {
    name: 'UserManagementForm',
    props: {
        id: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'add'
        },
        closeModal: {
            type: Function
        },
        userList: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    computed: {
        userLanguage() {
            const userLanguages = this.formData.userLanguages.filter(e => e.languageCode === this.formData.languageCode);
            return userLanguages[0];
        },
        editTableRules() {
            return {
                key: [
                    {
                        required: true,
                        message: '请输入键',
                        trigger: 'blur'
                    }
                ],
                value: [
                    {
                        required: true,
                        message: '请输入值',
                        trigger: 'blur'
                    }
                ]
            };
        },
        formRules() {
            return {
                account: [
                    {
                        required: true,
                        message: '请输入用户账号',
                        trigger: 'blur'
                    }
                ],
                password: [
                    {
                        required: false,
                        message: '请输入用户密码',
                        trigger: 'blur'
                    }
                ],
                languageCode: [
                    {
                        required: true,
                        message: '请输入语言',
                        trigger: 'blur'
                    }
                ],
                name: [
                    {
                        required: true,
                        message: '请输入名称',
                        trigger: 'blur'
                    }
                ],
                type: [
                    {
                        required: true,
                        message: '请输入用户类型',
                        trigger: 'blur'
                    }
                ],
                rank: [
                    {
                        required: true,
                        message: '请输入排序',
                        trigger: 'blur'
                    }
                ],
                state: [
                    {
                        required: true,
                        message: '请输入启用状态',
                        trigger: 'blur'
                    }
                ],
                isDeleted: [
                    {
                        required: true,
                        message: '请输入是否删除',
                        trigger: 'blur'
                    }
                ]
            };
        }
    },
    data() {
        return {
            editTable: [],
            formData: {
                account: '',
                name: '',
                languageCode: '',
                userLanguages: [],
                state: '',
                isDeleted: '',
                rank: '',
                password: '',
                type: ''
            },
            keyOptions: [
                {
                    value: 'namePinyin',
                    label: 'namePinyin'
                },
                {
                    value: 'email',
                    label: 'email'
                },
                {
                    value: 'mobile',
                    label: 'mobile'
                },
                {
                    value: 'sex',
                    label: 'sex'
                },
                {
                    value: 'openId',
                    label: 'openId'
                },
                {
                    value: 'virtualUser',
                    label: 'virtualUser'
                },
                {
                    value: 'address',
                    label: 'address'
                },
                {
                    value: 'jobNumber',
                    label: 'jobNumber'
                },
                {
                    value: 'resignTime',
                    label: 'resignTime'
                },
                {
                    value: 'firstName',
                    label: 'firstName'
                },
                {
                    value: 'lastName',
                    label: 'lastName'
                },
                {
                    value: 'firstNamePinyin',
                    label: 'firstNamePinyin'
                },
                {
                    value: 'lastNamePinyin',
                    label: 'lastNamePinyin'
                }
            ],
            keyValues: {
                userType: [],
                languages: []
            }
        };
    },
    methods: {
        async bindKeyValues() {
            const data = await Promise.all([this.$enum('EUserTypeEnum'), this.$client.getLanguages()]);
            this.keyValues.userType = data[0] || [];
            this.keyValues.languages = data[1] || [];
        },
        async initFormValue() {
            await this.bindKeyValues();
            const {type, id, userList} = this;
            if (userList) {
                userList.user.languageCode = this.keyValues.languages[0];
            }
            if ((type === 'edit' || type === 'detail') && id != null) {
                this.getUser(this.id);
            }
        },
        changeName(name) {
            this.formData.name = name;
        },
        getUser() {
            if (this.userList.user.type === 0) {
                this.userList.user.type = '';
            }
            this.formData = {...this.userList.user};
            const extra = this.userList.user.extraInfo;
            const arr = [];
            for (const i in extra) {
                const o = {};
                o[i] = extra[i];
                arr.push(o);
            }
            const result = arr.map(v => {
                const key = Object.keys(v)[0];
                return {
                    key,
                    value: v[key]
                };
            });
            this.editTable = result || [];
        },
        //删除一行数据
        handleEditTableDelete(index) {
            const length = this.editTable.length - 1;
            if (length !== index) {
                const emptyKey = this.editTable[this.editTable.length - 1].key;
                const emptyValue = this.editTable[this.editTable.length - 1].value;
                if (emptyKey === '' || emptyValue === '') {
                    this.$message({
                        message: '请先保存修改数据',
                        type: 'warning'
                    });
                    return false;
                }
            }
            this.editTable.splice(index, 1);
            this.$refs.editTableForm.setEditingRow(null, true);
        },
        //新增数据
        async handleEditTableRowAdd() {
            if (this.editTable.length > 0) {
                const emptyKey = this.editTable[this.editTable.length - 1].key;
                const emptyValue = this.editTable[this.editTable.length - 1].value;
                if (emptyKey === '' || emptyValue === '') {
                    return;
                }
            }
            const row = {
                key: '',
                value: ''
            };
            this.editTable.push(row);
            this.$refs.editTableForm.setEditingRow(row, false);
        },
        submitHandle(cb) {
            this.$refs.form.validate(valid => {
                const Json = {};
                for (let i = 0; i < this.editTable.length; i++) {
                    Json[this.editTable[i].key] = this.editTable[i].value;
                }
                const data = Object.assign({}, this.formData, {
                    extraInfo: Json
                });
                if (this.type === 'edit') {
                    if (valid) {
                        this.$client.editUser(data).then(() => {
                            this.$message({
                                message: '操作成功！',
                                type: 'success'
                            });
                            cb();
                        });
                    }
                } else if (valid) {
                    const tmp = {...data};
                    delete tmp.id;
                    this.$client.addUser(tmp).then(() => {
                        this.$message({
                            message: '操作成功！',
                            type: 'success'
                        });
                        cb();
                    });
                }
            });
        }
    },
    created() {
        this.initFormValue();
    }
};
</script>
<style lang="less" scoped>
/deep/ [label][form] {
    width: 100%;
    text-align: left;
}
</style>

