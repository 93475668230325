<template>
    <div class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100">
        <div class="ui-layout-content--full ui-form-actions">
            <el-button type="primary" size="mini" @click="addHandle">{{'新建'}}</el-button>
            <el-button type="primary" size="mini" @click="editHandle">{{'编辑'}}</el-button>
            <el-button type="primary" size="mini" @click="showDetail">{{'详情'}}</el-button>
            <el-button type="primary" size="mini" @click="deleteHandle">{{'删除'}}</el-button>
        </div>
        <div class="ui-layout-content--full ui-layout-flex--1">
            <ex-search-table-pagination
                ref="searchTable"
                :fetch="$client.getUserList"
                :columns="columns"
                :formOptions="formOptions"
                @selection-change="selectHandle"
            ></ex-search-table-pagination>
        </div>
        <el-dialog
            width="700px"
            :title="dialog.title"
            v-if="dialog.visible"
            :visible.sync="dialog.visible"
            :modal-append-to-body="true"
            :close-on-click-modal="false"
            :append-to-body="true"
            :show-close="true"
        >
            <user-management-form
                ref="editForm"
                :id="dialog.currentId"
                :type="dialog.type"
                :userList="dialog.userList"
            ></user-management-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeModalHandle" size="mini">
                    {{dialog.type === 'detail' ? '关闭' : '取消'}}
                </el-button>
                <el-button
                    type="primary"
                    @click="submitHandle"
                    size="mini"
                    v-if="dialog.type !== 'detail'"
                >{{'确定'}}</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import UserManagementForm from './UserManagementForm';
export default {
    name: 'UserManagement',
    components: {
        UserManagementForm
    },
    data() {
        return {
            selectedRows: [],
            dialog: {
                visible: false,
                title: '',
                currentId: '',
                tyle: '',
                userList: {}
            },
            allowEditUserSources: []
        };
    },
    computed: {
        columns() {
            return [
                {
                    type: 'selection'
                },
                {
                    prop: 'username',
                    label: '用户账号'
                },
                {
                    prop: 'type',
                    label: '用户类型'
                },
                {
                    prop: 'rank',
                    label: '排序'
                },
                {
                    prop: 'state',
                    label: '启用状态'
                },
                {
                    prop: 'isDeleted',
                    label: '是否删除'
                }
            ];
        },
        formOptions() {
            return {
                forms: [
                    {
                        prop: 'account',
                        label: '用户账号',
                        placeholder: '请输入用户账号',
                    }
                ]
            };
        }
    },
    methods: {
        selectHandle(val) {
            this.selectedRows = val;
        },
        closeModalHandle() {
            this.dialog.visible = false;
        },
        addHandle() {
            this.dialog = {
                title: '新建',
                currentId: undefined,
                visible: true,
                type: 'add'
            };
        },
        editHandle() {
            if (this.handleValidate('edit')) {
                let allowEdit = false;
                if (this.selectedRows[0].user.extraInfo && this.selectedRows[0].user.extraInfo.sources) {
                    allowEdit =
                        this.selectedRows[0].user.extraInfo.sources
                            .split(',')
                            .filter(e => this.allowEditUserSources.indexOf(e) !== -1).length > 0;
                } else {
                    allowEdit = false;
                }
                if (!allowEdit) {
                    this.$message({
                        message: '不允许修改该用户',
                        type: 'warning'
                    });
                    return;
                }
                this.dialog = {
                    currentId: this.selectedRows[0].user.id,
                    title: '编辑',
                    visible: true,
                    type: 'edit',
                    userList: this.selectedRows[0]
                };
            }
        },
        deleteHandle() {
            if (this.handleValidate('delete')) {
                this.$confirm('确认删除？', {
                    type: 'warning'
                })
                    .then(() => {
                        const {selectedRows} = this;
                        const ids = selectedRows.map(item => item.user.id);
                        this.$client.deleteUser(ids).then(() => {
                            this.$message({
                                message: '操作成功！',
                                type: 'success'
                            });
                            this.$refs.searchTable.searchHandler();
                        });
                    });
            }
        },
        showDetail() {
            if (this.handleValidate('detail')) {
                this.dialog = {
                    currentId: this.selectedRows[0].user.id,
                    title: '详情',
                    visible: true,
                    type: 'detail',
                    userList: this.selectedRows[0]
                };
            }
        },
        handleValidate(type) {
            const {selectedRows} = this;
            const length = selectedRows.length;
            if (length < 1) {
                this.$message({
                    message: '请先选择待操作数据',
                    type: 'warning'
                });
                return false;
            }
            if ((type === 'edit' || type === 'detail') && length > 1) {
                this.$message({
                    message: '只允许对一条进行操作',
                    type: 'warning'
                });
                return false;
            }
            return true;
        },
        submitHandle() {
            this.$refs.editForm.submitHandle(rest => {
                this.closeModalHandle();
                this.$refs.searchTable.searchHandler(rest);
            });
        }
    },
    created() {
        this.$client.getConfigValueByCode({code: 'AllowEditUserSources'}).then(data => {
            if (data) {
                this.allowEditUserSources = JSON.parse(data);
            }
        });
    }
};
</script>
